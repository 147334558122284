<template>
    <div>
        <!-- Navigation Bar-->
        <navbar></navbar>
        <!-- Navigation ends -->
        <div class="wrapper">

            <!-- Breadcrumbs -->
            <div class="breadcrmb-wrap hidden-xs">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">Home</a></li>
                                <li class="breadcrumb-item active"><a href="#">All Stores</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <!--end:Breadcrumbs -->
            <section class="results m-t-30">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-3">
                            <!-- // tags -->
                            <div class="widget">
                                <!-- /widget heading -->
                                <div class="widget-heading">
                                    <h3 class="widget-title text-dark">Filter</h3>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="widget-body">
                                    <div class="row vertical-menu">
                                        <div class="col-md-12 item" v-for="(storeGroup, index) in fillteredStores"
                                            :key="index">
                                            <a :href="'#store-'+ index">
                                                <div class="media">
                                                    <!-- <router-link :to="'/store/'+store._id"> -->
                                                    <div
                                                        class="media-left text-left media-middle col-lg-4 pointer top-store-img">
                                                        <h4>{{index.toUpperCase()}}</h4>
                                                    </div>
                                                    <div class="media-body top-store-text">
                                                        <span
                                                            class="badge badge-secondary text-right">{{storeGroup.length}}</span>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/col -->
                        <div class="col-sm-9 ">
                            <div class="row stores-background">
                                <div class="col-12">
                                    <div v-for="(data,index) in topStores.slice(0,8)" :key="index" class="col-md-3">
                                        <div class="featured-stores" :title="data.name">
                                            <router-link :to="`/store/${data.slug}`">
                                                <img v-if="data.image" class="img-responsive"
                                                    :src="$base_url + data.image"
                                                    :alt="data.name" />
                                                <img v-else class="img-responsive" src="../assets/placeholder.png"
                                                    :alt="data.name" />
                                            </router-link>
                                            <!-- <div class="type">Store Name</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-t-30">
                                <div class="col-12">
                                    <div class="stores-background m-b-20 p-t-10 p-b-10 anchor"
                                        style="border-left: 3px solid #F04732;"
                                        v-for="(storeGroup, index) in fillteredStores" :key="index"
                                        :id="'store-'+ index.toUpperCase()">
                                        <h4 class="store-title">Store - {{index.toUpperCase()}}</h4>
                                        <div class="row">
                                            <div class="col-md-3" v-for="(data,i) in storeGroup" :key="i">
                                                <router-link :to="`/store/${data.slug}`">
                                                    <i class="ti-link "></i> {{data.name}}
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Footer -->
            <foot />
        </div>
    </div>
    <!-- //wrapper -->
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'

    import {
        mapState
    } from "vuex";
    import {
        commonCopyMixin
    } from "../components/Mixins/commonCopyMixin";

    export default {
        name: "StorePage",
        mixins: [commonCopyMixin],
        components: {
            Foot: () => import( /* webpackPrefetch: true */ '../components/core/Footer'),
            Navbar: () => import( /* webpackPrefetch: true */ '../components/core/Navbar'),
            TopStore: () => import( /* webpackPrefetch: true */ '../components/home/TopStore'),
        },
        data() {
            return {
                eachCouponOffer: [],
                textToCopy: null,
                banners: [],
                loader: true,
            };
        },
        async created() {
            this.loader = true;
            this.$store.dispatch("coupon/fetchTopStores").then((res) => {});
            this.$store.dispatch('coupon/googleVerification')
        },
        mounted() {
            this.loader = false;
        },
        computed: {
            ...mapState({
                topStores: (state) =>
                    state.coupon.topStores ? state.coupon.topStores : [],
                showLoader: (state) => state.coupon.showLoader,
            }),
            fillteredStores() {
                let stores = {};
                this.topStores.forEach(function (store) {
                    let chat = store.name.charAt(0);
                    let tempArr = stores[chat.toUpperCase()] ?? new Array();
                    tempArr.push(store)
                    stores[chat.toUpperCase()] = [...tempArr];
                });

                return Object.fromEntries(Object.entries(stores).sort());
            }
        },

    };
</script>

<style scoped>
    .stores-background {
        padding: 5px 10px 8px;
        background-color: #fff;
        box-shadow: 0 1px 2px #d9dadb;
    }

    .badge {
        font-size: 12px;
        font-weight: 400;
        margin-top: 12px;
        float: right;
        padding: 3px 5px;
    }

    .featured-stores-title {
        font-size: 16px;
        font-weight: 500;
        float: left;
        margin-top: 0;
        margin-bottom: 0;
        color: black;
    }

    .featured-stores {
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 9%);
        height: 100px;
        margin: 1.5rem 0;
        padding: 5px;
    }

    .store-title {
        color: #F04732;
        font-size: 18px;
        font-weight: 500;
    }

    .featured-stores:hover {
        box-shadow: 0 1px 15px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 4 / 9%);
        height: 100px;
        margin: 1.5rem 0;
        padding: 5px;
    }

    .img-container {
        width: 100%;
        object-fit: contain;
        /* aspect-ratio: 16 / 9; 16:9 Aspect Ratio */
    }

    .img-responsive {
        width: 85px;
        margin: auto;
    }

    .sk-spinner.sk-wave {
        margin: auto;
    }

    .mb5 {
        margin-bottom: 5px;
    }

    .vertical-menu {
        padding: 1rem;
    }

    .vertical-menu .item {
        padding: 1rem;
        border: 1px solid #dee2e6;
        border-top: 0;
        display: flex;
        justify-content: space-between;
    }

    .vertical-menu .item:first-child {
        border-top: 1px solid #dee2e6;
        border-radius: 3px 3px 0 0;
    }

    .vertical-menu .item:last-child {
        border-radius: 0 0 3px 3px;
    }

    .vertical-menu .item:hover {
        background: rgba(0, 0, 0, 0.03);
    }

    .anchor {
        scroll-margin-top: 150px;
    }
</style>